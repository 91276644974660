<template>
  <v-select
    v-model="selectedFilters"
    label="Promotion Search Filters"
    :items="filters"
    item-text="name"
    item-value="key"
    background-color="input"
    :menu-props="{ 
      value: selectMenu, offsetY: true, maxHeight: '600', 
      closeOnContentClick: false, closeOnClick: true, openOnClick: true
    }"
    :disabled="disabled"
    style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-right: 0px;"
    multiple
    outlined
    hide-details
    small-chips
    dense>
    <template v-slot:selection="{ attrs, item }">
      <template v-if="item.key === 'promo_category_ids' && (params.promo_category_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedPromoCategories"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedPromoCategories.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'vendor_party_ids' && (params.vendor_party_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedVendors"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedVendors.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'category_manager_ids' && (params.category_manager_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedCategoryManagers"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedCategoryManagers.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <v-chip
        v-else
        v-bind="attrs"
        :color="item.color || null"
        :dark="Boolean(item.color)"
        small>
        {{ getFilterText(item) }}
        <v-icon
          small
          right
          @click="removeFilter(item.key, true)">
          $delete
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-list-item>
        <v-list-item-content style="overflow: visible">
          <VendorSelect v-if="item.key === 'vendor_party_ids'" :selectedVendors.sync="selectedVendors"/>
          <v-autocomplete
            v-else-if="item.key === 'promo_category_ids'"
            v-model="params.promo_category_ids"
            :label="item.name"
            :items="sortedPromoCategories"
            item-text="name"
            item-value="id"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            clearable
            outlined
            dense>
          </v-autocomplete>
          <v-autocomplete
            v-else-if="item.key === 'category_manager_ids'"
            v-model="params.category_manager_ids"
            :label="item.name"
            :items="sortedCategoryMangers"
            item-text="name"
            item-value="id"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            clearable
            outlined
            dense>
          </v-autocomplete>
          <div id="ad-date-menu" v-else-if="item.key === 'ad_date'" style="position: relative;">
            <v-text-field
              clearable
              dense
              hide-details
              label="Ad Date"
              outlined
              readonly
              prepend-inner-icon="mdi-calendar"
              @click:clear="removeFilter(item.key, true)"
              @click="showAdDateMenu = !showAdDateMenu"
              :value="dateFormatted(params.ad_date)">
            </v-text-field>
            <v-card
              v-if="showAdDateMenu"
              style="position: absolute; top: 50px; z-index: 999999;">
                <v-date-picker
                :show-current="false"
                v-model="params.ad_date"
                no-title
                @change="handleChangedDate(item)">
              </v-date-picker>
            </v-card>
          </div>
          <v-text-field
            v-else
            v-model="params[`${item.key}`]"
            :label="item.name"
            hide-details
            background-color="input"
            :prefix="item.prefix || null"
            :type="item.type"
            dense
            clearable
            outlined
            @keyup.stop="attrs.inputValue ? null : handleInputValue(item)"
            @keydown.enter="search"
            @click:clear="removeFilter(item.key)">
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
// components
import VendorSelect from '@/components/features/promotions/VendorSelect'
// mixins
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third-party
import { debounce } from 'lodash'
export default {
  data() {
    return {
      selectMenu: false,
      selectedFilters: [],
      selectedVendors: [],
      showAdDateMenu: false,
      params: {
        promo_number: '',
        promo_name: '',
        promo_category_ids: [],
        category_manager_ids: [],
        billing_mode_ids: [],
        vendor_party_ids: [],
      }
    }
  },
  name: 'SearchFilters',
  components: { VendorSelect },
  mixins: [userAccess, utils],
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    document.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClick)
  },
  watch: {
    activeFilters: {
      handler: debounce(function (newValue) {
        if (newValue) {
          this.updateSearchFilters()
        }
      }, 400),
      deep: true
    },
    selectedVendors: {
      handler(newValue) {
        this.handleChangedVendors(newValue, 'vendor_party_ids')
      },
      deep: true
    }
  },
  computed: {
    activeFilters() {
      return Object.keys(this.params).filter(this.isActive)
    },
    filters() {
      return [
        { name: 'Ad Date', key: 'ad_date', type: 'date', show: this.$auth.tenant === 'alliance-retail-group' },
        { name: 'Promotion Number', key: 'promo_number', type: 'text', show: true },
        { name: 'Promotion Name', key: 'promo_name', type: 'text', show: true },
        { name: 'Promotion Category', key: 'promo_category_ids', type: 'text', color: 'amber darken-2', show: true },
        { name: 'Category Manager', key: 'category_manager_ids', type: 'text', show: this.$auth.tenant === 'pricechopper' },
        { name: 'Promotion Type', key: 'billing_mode_ids', color: 'cyan darken-2', show: true },
        { name: 'Vendors', key: 'vendor_party_ids', color: 'red', show: true },
      ].filter( item => item.show)
    },
    sortedPromoCategories () {
      const categories = [...this.promoCategories]
      return this.sortByKey(categories, 'name')
    },
    sortedCategoryMangers () {
      const categories = [...this.categoryManagers]
      return this.sortByKey(categories, 'name')
    },
    sortedBillingModes () {
      const categories = [...this.billingModes]
      return this.sortByKey(categories, 'name')
    },
    selectedPromoCategories () {
      return this.sortedPromoCategories.filter(c => this.params.promo_category_ids.includes(c?.id))
    },
    selectedCategoryManagers () {
      return this.sortedCategoryMangers.filter(c => this.params.category_manager_ids.includes(c?.id))
    },
    selectedBillingModes () {
      return this.sortedBillingModes.filter(c => this.params.billing_mode_ids.includes(c?.id))
    },
  },
  methods: {
    search() {
      this.updateSearchFilters()
      this.$emit('search')
    },
    handleClick(e) {
      e.stopPropagation()
      const adDateMenu = document.querySelector('#ad-date-menu')
      if (adDateMenu && !adDateMenu.contains(e.target) && this.showAdDateMenu) {
          this.showAdDateMenu = false
      }
    },
    isActive(key) {
      if (this.isSelected(key) && Boolean(this.params[key])) {
        const value = this.params[key]
        return Array.isArray(value) ? (value.length > 0) : true
      }
      return false
    },
    getFilterText (item) {
      if (item.key === 'promo_category_id') {
        return this.selectedPromoCategories?.name
      } else if (item.key === 'billing_mode_id') {
        return this.selectedBillingMode?.name
      } else if (item.key === 'category_manager_ids') {
        return this.selectedCategoryManagers?.name
      }
      return `${item.text || item.name}: "${this.params[item.key]}"`
    },
    removeFilter(key, clearValue = false) {
      const index = this.selectedFilters.indexOf(key)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
        if (clearValue) this.params[key] = null
      }
    },
    removeVendor(id) {
      const index = this.selectedVendors.findIndex(v => v.id === id)
      if (index > -1) {
        this.selectedVendors.splice(index, 1)
      }
    },
    handleInputValue: debounce(function (item) {
      if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
        this.selectedFilters.push(item.key)
      }
    }, 200),
    handleChangedVendors(newValue, key) {
      this.params.vendor_party_ids = newValue.flatMap(v => v ? v.id : [])
      this.handleChangedArray(newValue, key)
    },
    handleChangedArray (newValue, key) {
      if ((newValue.length > 0) && !this.isSelected(key)) {
        this.selectedFilters.push(key)
      } else if ((newValue.length === 0) && this.isSelected(key)) {
        this.removeFilter(key)
      }
    },
    isSelected(key) {
      return this.selectedFilters.includes(key)
    },
    updateSearchFilters() {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key]
        return obj
      }, {})
      this.$emit('input', filtersObj)
    },
    handleChangedDate (item) {
      this.showAdDateMenu = false
      this.handleInputValue(item)
    },
    dateFormatted (dateVal) {
      if (dateVal) {
        return this.$dateConfig.formatDate(dateVal)
      }
    }
  }
}
</script>